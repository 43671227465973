<template>
  <!-- Danger Zone -->
  <div class="bg-red-50 border border-red-300 rounded-lg p-6 pb4">
    <div class="flex items-center mb-2">
      <div>
        <img
          src="@/assets/danger-warning.svg"
          alt="Warning"
          class="h-5 w-5 mr-2"
        />
      </div>
      <h3
        class="text-red-600 font-semibold flex items-center text-lg"
      >
        Danger Zone
      </h3>
    </div>

    <div class="space-y-4">
      <div class="flex items-center justify-between">
        <div>
          <h4 class="text-sm font-medium text-gray-900">
            Change Site URI
          </h4>
          <p class="text-xs text-gray-500">
            Update the site's URI configuration
          </p>
        </div>
        <button
          class="bg-red-600 hover:bg-red-700 text-white py-2 px-3 rounded-3xl transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 text-sm"
          @click="$emit('updateURI')"
        >
          Change URI
        </button>
      </div>
      <div class="border-t border-red-200 pt-4 mt-4">
        <div class="flex items-center justify-between">
          <div>
            <h4 class="text-sm font-medium text-gray-900">
              Delete this site
            </h4>
            <p class="text-xs text-gray-500">
              Permanently remove this site and all its data
            </p>
          </div>
          <button
            class="bg-red-600 hover:bg-red-700 text-white py-2 px-3 rounded-3xl transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 text-sm"
            @click="$emit('deleteSite')"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SettingsDanger",
}
</script>
<style>
</style>