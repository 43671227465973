<template>
  <div class="settings-page">
    <label @click="showModal = !showModal" class="cursor-pointer">
      <font-awesome-icon icon="cog" />
    </label>
    <vue-final-modal
      v-model="showModal"
      :click-to-close="false"
      :content-style="{
        width: '100vw',
        height: '100vh',
      }"
    >
      <div class="bg-white inline-flex flex-col gap-3 px-6 py-4 w-full h-full overflow-auto items-start">
        <div class="inline-flex w-full items-center h-auto min-h-0 mb-2">
          <img
            src="@/assets/heading-point.svg"
            alt="Warning"
            class="h-6 w-6 mr-2"
          />
          <span class="text-lg font-bold">Site Settings</span>

          <ul class="flex flex-wrap ml-4">
            <li
              v-for="tab in settingsTabs"
              :key="tab.value"
              @click="selectedTab = tab.value"
            >
              <a
                href="#"
                :class="
                  selectedTab === tab.value
                    ? 'selected-tab-item'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                "
                class="inline-block p-1 border-b-2"
              >
                {{ tab.name }}
              </a>
            </li>
          </ul>

          <div class="ml-auto cursor-pointer" @click="close">
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <div class="px-3 w-full h-full overflow-auto">
          <div v-if="selectedTab === 'general'">
            <SettingsGeneral
              :title="title"
              :extFiles="extFiles"
              :fullReload="fullReload"
              :ajaxReload="ajaxReload"
              :globalVariables="globalVariables"
              v-on:update="$attrs['onUpdate']"
            />
          </div>
          <div v-if="selectedTab === 'auth'">
            <SettingsAuth
              :loginInclude="loginInclude"
              :loginRequired="loginRequired"
              :enableMobile="enableMobile"
              :enableIpad="enableIpad"
              :authUrl="authUrl"
              :authAPIMethod="authAPIMethod"
              :extraAuths="extraAuths"
              v-on:update="$attrs['onUpdate']"
            />
          </div>
          <div v-if="selectedTab === 'script'" class="h-full">
            <SettingsScript
              :globalJs="globalJs"
              v-on:update="$attrs['onUpdate']"
            />
          </div>
          <div v-if="selectedTab === 'style'" class="h-full">
            <SettingsStyle
              :globalCss="globalCss"
              v-on:update="$attrs['onUpdate']"
            />
          </div>
          <div v-if="selectedTab === 'danger'">
            <SettingsDanger
              v-on:updateURI="$attrs['onUpdateURI']"
              v-on:deleteSite="$attrs['onDeleteSite']"
            />
          </div>
        </div>
        <div class="flex ml-auto">
          <button
            class="bg-gray-300 hover:bg-gray-400 text-gray-800 py-1 px-2 rounded self-settings-cancel-btn"
            @click="close"
            :disabled="isSaving"
          >
            Close
          </button>
          <button
            class="ml-2 text-white py-1 px-2 rounded self-settings-add-btn"
            @click="save"
            :disabled="isSaving"
          >
            <span v-if="isSaving">
              <i class="fa-solid fa-spinner fa-spin"></i>
            </span>
            <span v-else>Save</span>
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import SettingsGeneral from "./siteSettings/General.vue";
import SettingsAuth from "./siteSettings/Auth.vue";
import SettingsScript from "./siteSettings/Script.vue";
import SettingsStyle from "./siteSettings/Style.vue";
import SettingsDanger from "./siteSettings/DangerZone.vue";

export default {
  name: "SiteSettings",
  components: {
    SettingsGeneral,
    SettingsAuth,
    SettingsScript,
    SettingsStyle,
    SettingsDanger,
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    extFiles: {
      type: Array,
      default: () => [],
    },
    fullReload: {
      type: [Number, String],
      default: 0,
    },
    ajaxReload: {
      type: [Number, String],
      default: 0,
    },
    loginInclude: {
      type: Boolean,
      default: false,
    },
    loginRequired: {
      type: Boolean,
      default: false,
    },
    authUrl: {
      type: String,
      default: () => "",
    },
    extraAuths: {
      type: Array,
      default: () => []
    },
    mobileQuery: {
      type: String,
      default: () => "",
    },
    authAPIMethod: {
      type: String,
      default: () => "GET",
    },
    globalCss: {
      type: String,
      default: () => "",
    },
    globalJs: {
      type: String,
      default: () => "",
    },
    globalVariables: {
      type: Array,
      default: () => [],
    },
    enableMobile: {
      type: Boolean,
      default: false,
    },
    enableIpad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      isSaving: false,
      settingsTabs: [
        {
          name: 'General Settings',
          value: 'general'
        },
        {
          name: 'Auth Settings',
          value: 'auth'
        },
        {
          name: 'Script Settings',
          value: 'script'
        },
        {
          name: 'Style Settings',
          value: 'style'
        },
        {
          name: 'Danger Zone',
          value: 'danger'
        }
      ],
      selectedTab: 'general'
    };
  },
  mounted() {
    console.log("this.$attrs: ", this.$attrs);
  },
  methods: {
    close() {
      this.showModal = false;
    },
    async save() {
      this.isSaving = true;
      try {
        await new Promise((resolve) => {
          this.$emit("save", resolve);
        });
      } finally {
        this.isSaving = false;
        this.close();
      }
    },
  },
};
</script>
<style scoped>
.selected-tab-item {
  color: #00aeef !important;
  border-color: #00aeef;
}


button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
