<template>
  <div class="site-self-settings" :style="{ height: height + 'px' }">
    <div class="p-4 bg-white">
      <!-- Main content -->
      <div
        class="text-sm font-medium text-center text-gray-500 border-gray-200 dark:text-gray-400 dark:border-gray-700"
      >
        <div class="bg-white p-3 border border-blue-100 rounded-md">
          <ul class="flex flex-wrap -mb-px border-b border-blue-100">
            <li
              v-for="tab in settingsTabs"
              :key="tab.value"
              class="me-2"
              @click="selectedTab = tab.value"
            >
              <a
                href="#"
                :class="
                  selectedTab === tab.value
                    ? 'selected-tab-item'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                "
                class="inline-block p-2 border-b-2"
              >
                {{ tab.name }}
              </a>
            </li>
          </ul>

          <div class="overflow-auto">
            <!-- Component Settings -->
            <div v-if="selectedTab === 'component_settings'">
              <ComponentSettings
                :currentComponent="currentComponent"
                :isComponentView="isComponentView"
                :settings="{
                  is_global: currentComponent.is_global,
                  name: currentComponent.name,
                }"
                @settings:change="setSettings"
              />
            </div>

            <!-- Props Tab -->
            <div v-if="selectedTab === 'props'">
              <PropsTab :propsList="currentComponent.props" @set="setProps" />
            </div>

            <!-- Constant Data Tab -->
            <div v-if="selectedTab === 'constant_data'">
              <ConstantDataTab
                :constants="constantData"
                @add="addNewConstantData"
                @update:item="updateConstantData"
                @remove:item="removeConstantData"
              />
            </div>

            <!-- Methods Tab -->
            <div v-if="selectedTab === 'methods'">
              <MethodsTab
                :methods="currentComponent.methods"
                @add="$emit('addMethod', $event)"
                @update="$emit('updateMethod', $event)"
                @remove="$emit('removeMethod', $event)"
              />
            </div>
            <div v-if="selectedTab === 'computed'">
              <ComputedTab
                :computed="currentComponent.computed"
                @add="$emit('addComputed', $event)"
                @update="$emit('updateComputed', $event)"
                @remove="$emit('removeComputed', $event)"
              />
            </div>

            <!-- Remote Data Component -->
            <div v-if="selectedTab === 'remote_data'">
              <RemoteData
                :remoteData="remoteData"
                @update="updateItem"
                @add="addNewRemoteData"
                @remove:item="removeRemoteData"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Drag bar for resizing height -->
      <div
        v-if="isEditable"
        class="resize-bar"
        @mousedown="startHeightResizing"
      >
        <img src="@/assets/vertical-resizer.svg" alt="Resize Handle" />
      </div>
    </div>
  </div>
</template>

<script>
import RemoteData from "@/components/RemoteData.vue";
import ComponentSettings from "@/components/ComponentSettings.vue";
import PropsTab from "@/components/PropsTab.vue";
import MethodsTab from "@/components/MethodsTab.vue";
import ConstantDataTab from "@/components/ConstantDataTab.vue";
import ComputedTab from "./ComputedTab.vue";

export default {
  name: "SiteSelfSetting",
  props: ["currentComponent", "isComponentView", "isEditable"],
  data() {
    return {
      settingsTabs: [
        {
          name: "Component Settings",
          value: "component_settings",
        },
        {
          name: "Props",
          value: "props",
        },
        {
          name: "Methods",
          value: "methods",
        },
        {
          name: "Computed",
          value: "computed",
        },
        {
          name: "Constant Data",
          value: "constant_data",
        },
        {
          name: "Remote Data",
          value: "remote_data",
        },
      ],
      selectedTab: "component_settings",
      componentName: "app",
      componentProps: ["", ""],
      height: 300, // Initial height
      isResizing: false,
      maxHeight: 640,
    };
  },
  components: {
    RemoteData,
    ComponentSettings,
    PropsTab,
    MethodsTab,
    ComputedTab,
    ConstantDataTab,
  },
  computed: {
    constantData() {
      return {
        label: "Const Data Source",
        type: "const",
        data: this.currentComponent.data
          .map((d, idx) => ({ ...d, idx }))
          .filter((item) => item.type === "const"),
        helpText: "Regular variable used within component.",
      };
    },
    remoteData() {
      return {
        label: "Remote Data Source",
        type: "remote",
        data: this.currentComponent.data
          .map((d, idx) => ({ ...d, idx }))
          .filter((item) => item.type === "remote"),
        helpText: "Remote data source fetched from remote api endpoint.",
      };
    },
  },
  methods: {
    startHeightResizing(event) {
      this.isResizing = true;
      this.initialMouseY = event.clientY;
      this.initialHeight = this.height;
      window.addEventListener("mousemove", this.resizeHeight);
      window.addEventListener("mouseup", this.stopHeightResizing);
    },
    resizeHeight(event) {
      if (this.isResizing) {
        const deltaY = event.clientY - this.initialMouseY;
        const newHeight = this.initialHeight + deltaY;

        // Check against min and max height
        this.height = Math.max(100, Math.min(newHeight, this.maxHeight)); // Minimum 100px and maximum maxHeight
      }
    },
    stopHeightResizing() {
      this.isResizing = false;
      window.removeEventListener("mousemove", this.resizeHeight);
      window.removeEventListener("mouseup", this.stopHeightResizing);
    },
    updateConstantData(newConstantData) {
      this.$emit("update", { idx: newConstantData.idx, data: newConstantData });
    },
    removeConstantData(idx) {
      this.$emit("remove", idx);
    },
    setSettings(newSettings) {
      this.$emit("update:settings", newSettings);
    },
    setProps(newProps) {
      this.$emit("set", newProps);
    },
    addNewConstantData({ name, type }) {
      // emit to parent
      this.$emit("add", {
        name,
        type,
      });
    },
    addNewRemoteData({ name, type }) {
      // emit to parent
      this.$emit("add", {
        name,
        type,
      });
    },
    removeRemoteData(item) {
      this.$emit("remove", item.idx);
    },
    updateItem(updatedItem) {
      this.$emit("update", { idx: updatedItem.idx, data: updatedItem.data });
    },
  },
};
</script>

<style scoped>
.site-self-settings {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  /* padding: 15px 15px 0px 15px !important; */
  background: #f8f8ff;
}

.bg-white {
  flex: 1 !important;
  overflow: auto !important;
}

.resize-bar {
  width: calc(100% - 30px) !important;
  height: 5px !important;
  background-color: #00aeef !important;
  cursor: row-resize !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 15px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.resize-bar img {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.selected-tab-item {
  color: #00aeef !important;
  border-color: #00aeef;
}
</style>
