<template>
  <div class="flex mt-3">
    <label class="text-left text-sm font-medium w-36 text-gray-700"
      >Auth :
    </label>
    <div class="flex items-center">
      <div class="flex items-center">
        <input
          :defaultChecked="loginInclude"
          type="checkbox"
          class="mr-2 custom-checkbox"
          @change="updateField('loginInclude', $event.target.checked)"
        />
        <label class="mr-3 text-xs text-left font-normal">
          Include Login
        </label>
      </div>

      <div class="flex items-center">
        <input
          :disabled="!loginInclude"
          :defaultChecked="loginRequired"
          type="checkbox"
          class="mr-2 custom-checkbox"
          @change="
            updateField('loginRequired', $event.target.checked)
          "
        />
        <label class="mr-3 text-xs text-left font-normal">
          Login Required
        </label>
      </div>

      <div class="flex items-center">
        <input
          :defaultChecked="enableMobile"
          type="checkbox"
          class="mr-2 custom-checkbox"
          @change="updateField('enableMobile', $event.target.checked)"
        />
        <label class="mr-3 text-xs text-left font-normal">
          Enable Mobile
        </label>
      </div>

      <div class="flex items-center">
        <input
          :defaultChecked="enableIpad"
          type="checkbox"
          class="mr-2 custom-checkbox"
          @change="updateField('enableIpad', $event.target.checked)"
        />
        <label class="mr-3 text-xs text-left font-normal">
          Enable Ipad
        </label>
      </div>
    </div>
  </div>

    <!-- AUTH API URL FIELD -->
  <div class="flex mt-3">
    <label
      class="text-left text-sm font-medium w-36 mt-1 text-gray-700"
    >
      Auth API URL :
    </label>
    <select
      :value="authAPIMethod"
      @change="updateField('authAPIMethod', $event.target.value)"
      class="px-2 py-1 border border-gray-300 custom-focus rounded-md w-20 mr-2 text-sm"
    >
      <option>GET</option>
      <option>POST</option>
      <option>PUT</option>
    </select>
    <input
      class="flex-grow px-3 py-2 border border-gray-300 custom-focus rounded-md h-8"
      type="text"
      placeholder="Auth URL"
      :defaultValue="authUrl"
      @change="updateField('authUrl', $event.target.value)"
    />
  </div>
  <div class="mt-3 items-center" v-for="(authItem, aIdx) of extraAuths" :key="aIdx">
    <div class="flex">
      <div
        class="text-left text-sm font-medium w-36 text-gray-700 flex items-center pr-2 gap-1"
      >
        <span title="Use ggAuth(your key) as Authorization header on remote source data">
          <font-awesome-icon icon="info-circle" />
        </span>
        ggAuth
        <input
          type="text" :value="authItem.key" placeholder="Key"
          class="min-w-0 flex-grow py-2 border border-gray-300 custom-focus rounded-md h-7"
          @change="updateExtraAuth(aIdx, 'key', $event.target.value)"
        />
      </div>
      <select
        :value="authItem.method"
        @change="updateExtraAuth(aIdx, 'method', $event.target.value)"
        class="px-2 py-1 border border-gray-300 custom-focus rounded-md w-20 mr-2 text-sm"
      >
        <option>GET</option>
        <option>POST</option>
        <option>PUT</option>
      </select>
      <input
        class="flex-grow px-3 py-2 border border-gray-300 custom-focus rounded-md h-8"
        type="text"
        placeholder="Auth Source"
        :defaultValue="authItem.url"
        @change="updateExtraAuth(aIdx, 'url', $event.target.value)"
      />
      <span
        class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
        @click="toggleExtraAuthConfig(aIdx)"
      >
        <font-awesome-icon icon="cog" />
      </span>
      <span
        class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
        @click="removeExtraAuth(aIdx)"
      >
        <font-awesome-icon icon="minus" />
      </span>
    </div>

    <div v-if="extraAuthEditingConfigIdx === aIdx" class="flex flex-col pl-36 my-2 pt-2 pr-2 bg-gray-100 rounded-md">
      <div class="flex gap-4 mb-1 text-sm text-gray-500 pl-3">
        <span class="cursor-pointer" @click="extraAuthEditingKey='headers'" :class="extraAuthEditingKey==='headers' && 'text-black'">
          Headers ({{ (authItem['headers'] || []).length }})
        </span>
        <span class="cursor-pointer" @click="extraAuthEditingKey='body'" :class="extraAuthEditingKey==='body' && 'text-black'">
          Body ({{ (authItem['body'] || []).length }})
        </span>
        <label class="flex items-center gap-1 cursor-pointer">
          <input
            type="checkbox" :checked="!!authItem.serverOnly"
            @change="updateExtraAuth(aIdx, 'serverOnly', !!$event.target.checked)"
          />
          <span class="ml-1">Server Side</span>
          <font-awesome-icon icon="info-circle" title="With this checkbox auth token will be grabbed from server side without user interaction" />
        </label>
      </div>
      <div class="flex flex-col">
        <div
          class="flex items-center gap-2 mb-2"
          v-for="(item, itemIdx) of (authItem[extraAuthEditingKey] || []).concat({key: '', value: '', new: true})"
          :key="`${extraAuthEditingKey}-${item.key}-${itemIdx}`"
        >
          <input
            type="text"
            :defaultValue="item.key"
            placeholder="Key"
            class="w-32 px-3 py-2 border border-gray-300 custom-focus rounded-md h-8"
            @change="updateExtraAuthConf(itemIdx, 'key', $event.target.value)"
          />
          <input
            type="text"
            :defaultValue="item.value"
            placeholder="Value"
            class="flex-1 px-3 py-2 border border-gray-300 custom-focus rounded-md h-8"
            @change="updateExtraAuthConf(itemIdx, 'value', $event.target.value)"
          />
          <span
            class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
            @click="item.new ? addExtraAuthConf(): removeExtraAuthConf(itemIdx)"
          >
            <font-awesome-icon :icon="item.new ? 'plus': 'minus'" />
          </span>
        </div>
        <div class="flex items-center mb-2">
          <input
            type="text"
            :defaultValue="authItem['response']"
            placeholder="Response. e.g token"
            title="Use this field if the api returns token in non plain text format. e.g { token: 'token_string'}"
            class="flex-1 px-3 py-2 border border-gray-300 custom-focus rounded-md h-8"
            @change="updateExtraAuth(aIdx, 'response', $event.target.value)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="flex mt-3">
    <div class="w-36"></div>
    <span class="text-xs text-gray-500 cursor-pointer" @click="addExtraAuth">
      Add another auth source
    </span>
  </div>
</template>
<script>
export default {
  name: "SettingsAuth",
  emits: ["update"],
  props: {
    loginInclude: Boolean,
    loginRequired: Boolean,
    enableMobile: Boolean,
    enableIpad: Boolean,
    authUrl: String,
    authAPIMethod: String,
    extraAuths: Array,
  },
  data() {
    return {
      extraAuthEditingConfigIdx: -1,
      extraAuthEditingKey: "headers"
    }
  },
  methods: {
    updateField(field, value) {
      console.log("field, value: ", field, value);
      this.$emit("update", { [field]: value });
    },
    updateExtraAuth(idx, field, value) {
      const clone = JSON.parse(JSON.stringify(this.extraAuths || []));
      clone[idx][field] = value;
      this.$emit("update", { extraAuths: clone });
    },
    removeExtraAuth(idx) {
      const clone = JSON.parse(JSON.stringify(this.extraAuths || []));
      clone.splice(idx, 1);
      this.$emit("update", { extraAuths: clone });
    },
    addExtraAuth() {
      this.$emit("update", {
        extraAuths: (this.extraAuths || []).concat({
          key: "",
          method: "GET",
          url: "",
        }),
      });
    },
    toggleExtraAuthConfig(idx) {
      if (this.extraAuthEditingConfigIdx === idx) {
        this.extraAuthEditingConfigIdx = -1;
        this.extraAuthEditingKey = "headers";
      } else {
        this.extraAuthEditingConfigIdx = idx;
        this.extraAuthEditingKey = "headers";
      }
    },
    updateExtraAuthConf(itemIdx, field, value) {
      const clone = JSON.parse(JSON.stringify(this.extraAuths || []));
      console.log(clone[this.extraAuthEditingConfigIdx][this.extraAuthEditingKey] || [])
      if (itemIdx === (clone[this.extraAuthEditingConfigIdx][this.extraAuthEditingKey] || []).length) {
        this.newExtraAuthConf = {
          ...(this.newExtraAuthConf || {}),
          [field]: value
        };
        return
      }
      clone[this.extraAuthEditingConfigIdx][this.extraAuthEditingKey][itemIdx][field] = value;
      this.$emit("update", { extraAuths: clone });
    },
    addExtraAuthConf () {
      const clone = JSON.parse(JSON.stringify(this.extraAuths || []));
      console.log(this.newExtraAuthConf)
      if (!clone[this.extraAuthEditingConfigIdx][this.extraAuthEditingKey]) {
        clone[this.extraAuthEditingConfigIdx][this.extraAuthEditingKey] = []
      }
      clone[this.extraAuthEditingConfigIdx][this.extraAuthEditingKey].push(this.newExtraAuthConf);
      this.newExtraAuthConf = null;
      console.log(clone)
      this.$emit("update", { extraAuths: clone });
    },
    removeExtraAuthConf (itemIdx) {
      const clone = JSON.parse(JSON.stringify(this.extraAuths || []));
      clone[this.extraAuthEditingConfigIdx][this.extraAuthEditingKey].splice(itemIdx, 1);
      this.$emit("update", { extraAuths: clone });
    }
  }
}
</script>
<style>
</style>