<template>
  <div class="h-full flex flex-col">
    <div class="mb-2">Put your global Stylesheet here</div>
    <div class="flex-1 min-h-0">
      <TemplateEditor
        :template="globalCss"
        currentLanguage="css"
        @set="updateCss"
      />
    </div>
  </div>
</template>
<script>
import TemplateEditor from "../TemplateEditor";

export default {
  name: "SettingsStyle",
  components: {
    TemplateEditor,
  },
  props: {
    globalCss: String,
  },
  methods: {
    updateField(field, value) {
      console.log("field, value: ", field, value);
      this.$emit("update", { [field]: value });
    },
    updateCss(code) {
      this.updateField("globalCss", code);
    },
  }
}
</script>
<style scoped>
.code-field {
  font-family: consolas;
}
</style>