<template>
  <div class="h-full flex flex-col">
    <div class="mb-2">Put your global Javascript here</div>
    <div class="flex-1 min-h-0">
      <TemplateEditor
        :template="globalJs"
        currentLanguage="javascript"
        @set="updateJs"
      />
    </div>
  </div>
</template>
<script>
import TemplateEditor from "../TemplateEditor";

export default {
  name: "SettingsScript",
  components: {
    TemplateEditor,
  },
  props: {
    globalJs: String,
  },
  methods: {
    updateField(field, value) {
      console.log("field, value: ", field, value);
      this.$emit("update", { [field]: value });
    },
    updateJs(code) {
      this.updateField("globalJs", code);
    },
  }
}
</script>
<style scoped>
.code-field {
  font-family: consolas;
}
</style>